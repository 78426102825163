import './App.css';
import { useMemo } from 'react';
import * as anchor from '@project-serum/anchor';
import Home from './Home';

import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const txTimeoutInMilliseconds = 30000;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <div style={{textAlign: "center", marginTop: "2em", width: "400px", maxWidth: "100%", color: "white", marginLeft: "1em", marginRight: "1em", borderRadius: "18px", borderColor: "rgba(255,255,255,.3)", borderWidth: "2px", borderStyle: "solid", padding: "1em", background: "rgba(0,0,0,.6)", boxShadow: "2px 3px 4px rgba(0,0,0,.7)"}}>
        <h1 style={{color: "white", fontSize: "1.5em",  fontFamily: "ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace"}} className="glow">
        Sol Visitors Are Landing!🛸<br />
        OFFICIAL MINT SITE<br />
        {/* <small>NOW ACCEPTING <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.png" alt="DUST" width="18" height="18" /> $DUST EXCLUSIVELY.</small> */}
        <small>NOW ACCEPTING <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq/logo.png" alt="FOXY" width="18" height="18" /> $FOXY EXCLUSIVELY.</small>
        </h1>
        <img src="/images/SolVisitors_Imprint_NFT.gif" alt="Sol Visitors First Wave: Imprints" style={{width: "140px", height: "auto", borderRadius: 12}} />
        <h2><strong>Sol Visitors First Wave: Imprints</strong></h2>
        <p>
        <em>Imprints detected! First wave of Sol Visitors, Imprints are a limited collection of 256 NFTs that connect you to the Sol Visitors universe.</em><br /><br />
        Cost to mint 1 IMPRINT:  5,000 $FOXY<br />
        Cost with a Sol Visitors $BEACON: 2,500 $FOXY
        </p>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletDialogProvider>
              <Home
                candyMachineId={candyMachineId}
                connection={connection}
                txTimeout={txTimeoutInMilliseconds}
                rpcHost={rpcHost}
              />
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
        <div style={{borderRadius: "10px", border: "2px dotted rgba(255,255,255,.3)", padding: "1em", margin: "1em 0 0 0"}}>
            <img src="https://raw.githubusercontent.com/gserafini/solvisitors/main/coin.png" alt="Sol Visitors Beacon" style={{width: "18px", height: "auto", margin: "-8px 4px 0 0", display: "inline"}} />Sol Visitors Beacon token {/*<code>(24GF...M4q)</code>*/}<br /> gives you 2,500 $FOXY discount.<br />
            <a href="https://discord.gg/TF7zW5q9Ur" target="_blank" rel="noopener noreferrer" style={{color: "pink"}}>Join our Discord to get your discount</a>
          </div>
        <div style={{textAlign: "center", marginTop: "2em", display: "flex", alignItems: "center"}}>
          <a href="https://twitter.com/civickey/status/1478456202069565443" target="_blank" rel="noopener noreferrer">
            <img src="/images/Civic-Verified-Logo-768x208.png" alt="Verified by Civic" style={{width: "200px", height: "auto"}} />
          </a>
          <span style={{margin:"0 .5em 0 2em", color: "grey"}}><em>As seen on:</em></span>
          <a href="https://nftcalendar.io/event/sol-visitors-first-wave-imprints/" target="_blank" rel="noopener noreferrer">
            <img src="/images/nftcalendar-500x500.png" alt="As seen on NFT Calendar" style={{width: "64px", height: "auto"}} />
          </a>
        </div>
      </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
